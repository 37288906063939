import { render, staticRenderFns } from "./TeachingMaterial.vue?vue&type=template&id=0b88c810&scoped=true"
import script from "./TeachingMaterial.vue?vue&type=script&lang=js"
export * from "./TeachingMaterial.vue?vue&type=script&lang=js"
import style0 from "./TeachingMaterial.vue?vue&type=style&index=0&id=0b88c810&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b88c810",
  null
  
)

export default component.exports